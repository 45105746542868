<template>
  <v-container>
    <v-card-title> 로그인 </v-card-title>
    <v-card-text class="pb-0">
      <v-alert
        v-if="alertSuccess"
        dense
        text
        type="success"
      >
        <strong>로그인 성공 !</strong>
      </v-alert>
      <v-alert
        v-if="alertFail"
        dense
        text
        type="error"
      >
        <strong>로그인 실패</strong>
      </v-alert>

      <v-form>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="이메일"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          label="비밀번호"
          :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
          :type="isShow ? 'text' : 'password'"
          required
          @click:append="isShow = !isShow"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        />
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <v-btn
              block
              class="mr-4"
              @click="clear"
            >
              지우기
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
          >
            <v-btn
              color="primary"
              block
              @click="loginsubmit"
            >
              로그인
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text
          class="mt-5 mb-0 pb-0"
          :class="setColor"
          @click="resetPassword"
          @mouseover="mouseOverBtn = !mouseOverBtn"
          @mouseleave="mouseOverBtn = !mouseOverBtn"
        >
          <p>
            <strong>비밀번호를 잊으신 경우</strong><br />
            가입하신 이메일을 입력한 뒤 여기를 눌러주세요
          </p>
        </v-card-text>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email, password } from 'vuelidate/lib/validators'
import { mapMutations, mapActions } from 'vuex'
import { getNewPassword } from '../../plugins/firebase'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },

  data: () => ({
    email: '',
    password: '',
    isShow: false,
    alertFail: false,
    alertSuccess: false,
    mouseOverBtn: false,
    setColor: 'colorDefault',
  }),

  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      // !this.$v.password.email && errors.push('Must be valid e-mail')
      // !this.$v.password.required && errors.push('password is required')
      return errors
    },
  },
  methods: {
    ...mapActions('user', ['login']),
    ...mapMutations('common', ['setLoading']),
    loginsubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.setLoading(true)
      this.login({
        email: this.email,
        password: this.password,
      }).then(res => {
        if (res == 'ERROR') {
          this.alertFail = true
        } else {
          this.alertSuccess = true
          setTimeout(() => {
            this.$router.push('/create')
          }, 1000)
        }
        this.setLoading(false)
      })
    },
    clear() {
      this.$v.$reset()
      this.email = ''
      this.password = ''
    },
    async resetPassword() {
      const res = await getNewPassword(this.email)
      if (res === 'SUCCESS') {
        alert('이메일이 발송되었습니다. 메일을 확인해주세요.')
      } else {
        alert('이메일을 다시 확인해주세요. ')
      }
    },
    passwordBtnColor() {
      this.resetPasswordColor = 'blue'
    },
  },
  watch: {
    mouseOverBtn(val) {
      if (val === true) {
        this.setColor = 'colorBlue'
      } else {
        this.setColor = 'colorDefault'
      }
    },
  },
}
</script>
<style scoped>
.colorDefault {
  color: rgba(0, 0, 0, 0.87);
}
.colorBlue {
  /* color: blue; */
  background: -webkit-linear-gradient(rgb(13, 101, 254), rgb(1, 59, 118));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
